// Token
const tokenStorageKey = "JWT_Token"

// Token有效期
const tokenExpireStorageKey = "JWT_TokenExpire"

export {
	tokenStorageKey,
	tokenExpireStorageKey,
}
