
import axios from 'axios';

import { tokenStorageKey } from '@/common/user-defaults.js';

const baseURL = 'http://linksee-dashboard-api.linkseeiot.com';

const http = axios.create({
  timeout: 3000,
  baseURL: baseURL,
});

http.interceptors.request.use(config => {
	console.log("http.interceptors.request...." + JSON.stringify(config));
	// 设置Token
	let token = localStorage.getItem(tokenStorageKey);
	if (token) {
		config.headers.authorization = token
	}
	return config;
}, err => {
	return Promise.reject(err);
})

http.interceptors.response.use(async (response) => {
	// console.log("http.interceptors.response...." + JSON.stringify(response));
	return response
}, err => {
	return Promise.reject(err)
})

export {
	http,
}
